import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReorderQuickContacts } from '../../../../services/quick-contacts';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { ReorderButton } from './components/ReorderButton/ReorderButton';

export const ReorderContacts = () => {
  const translations = useTranslations();
  const { quickContactsState, handleSetReorderMode } = useQuickContactsFilterContext();

  const handleTurnOnReorderMode = () => handleSetReorderMode(true);
  const handleTurnOffReorderMode = () => {
    handleSetReorderMode(false);
  };

  const { mutate: reorderQuickContacts, isLoading: isSavingOrder } = useReorderQuickContacts({
    onSuccess: () => handleSetReorderMode(false),
  });

  const handleReorder = () => {
    if (!quickContactsState.selectedFnbGroupId) {
      toast.info(translations('quick_contacts_fnb_group_missing_message'));
    } else if (!quickContactsState.newOrder) {
      toast.info(translations('quick_contacts_reorder_no_changes_message'));
      handleTurnOffReorderMode();
    } else if (quickContactsState.selectedFnbGroupId && quickContactsState.newOrder) {
      const request = quickContactsState.newOrder?.map((quickContact) => {
        return { id: quickContact.id, type: quickContact.type };
      });
      reorderQuickContacts({
        fnbGroupId: quickContactsState.selectedFnbGroupId,
        reorderRequest: { quickContactsElementsSorted: request },
      });
    }
  };

  return (
    <>
      {quickContactsState.isReorderMode ? (
        <>
          <Button variant={'orangeSolid'} width={'100%'} mr={2} onClick={handleReorder} isLoading={isSavingOrder}>
            {translations('quick_contacts_save_order_button')}
          </Button>
          <Button variant={'greySolid'} width={'100%'} onClick={handleTurnOffReorderMode}>
            {translations('quick_contacts_cancel_order_button')}
          </Button>
        </>
      ) : (
        <ReorderButton onClick={handleTurnOnReorderMode} />
      )}
    </>
  );
};
