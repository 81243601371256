import React from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { ItemsSummaryAccordion } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryStatusText';
import { RelevoSimpleTableContainer } from '../../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { QuickContactElement, QuickContactFailureResponse } from '../../../../model/quick-contacts-create-response';

type AddNewContactsResultModalBodyProps = {
  failureItems: QuickContactFailureResponse[];
  successItems: QuickContactElement[];
};

export const AddNewContactsResultModalBody = ({ successItems, failureItems }: AddNewContactsResultModalBodyProps) => {
  const translations = useTranslations();
  const summaryText = translations('quick_contacts_create_result_modal_summary_text', {
    '{{successItems}}': successItems.length.toString(),
    '{{allItems}}': (successItems.length + failureItems.length).toString(),
  });

  return (
    <>
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion
            buttonText={translations('quick_contacts_create_result_modal_failure_text')}
            isOpen={true}
          >
            <RelevoSimpleTableContainer>
              <Table variant="relevoSummary" overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>{translations('quick_contacts_type')}</Th>
                    <Th>{translations('quick_contacts_client')}</Th>
                    <Th>{translations('error')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {failureItems
                    ? failureItems.map((item, index) => (
                        <Tr key={`${item.quickContact.type}${index}`}>
                          <Td>{item.quickContact.type}</Td>
                          <Td>{item.quickContact.nickname || item.quickContact.restaurantId}</Td>
                          <Td>{item.message || item.error}</Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </RelevoSimpleTableContainer>
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </>
  );
};
