import { CreateNewContactForm } from '../components/AddNewQuickContact/components/AddNewContactsModal/form/create-new-contact-form';
import { QuickContactType } from '../model/enums/quick-contact-type.enum';

export const mapCreateFormToCreateRequest = (form: CreateNewContactForm) => {
  switch (form.type) {
    case QuickContactType.USER:
      return [{ type: form.type, nickname: form.nickname }];
    case QuickContactType.RESTAURANT:
      return form.selectedRestaurants
        ? form.selectedRestaurants?.map((restaurant) => {
            return { type: form.type, restaurantId: restaurant };
          })
        : [];
    default: {
      const exhaustiveCheck: never = form.type;
      throw new Error(exhaustiveCheck);
    }
  }
};
