import React, { useRef } from 'react';
import { Box, Text } from '@chakra-ui/react';

import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useDeleteQuickContact } from '../../../../services/quick-contacts';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { QuickContactResponse } from '../../model/quick-contact-response';

type DeleteQuickContactModalProps = {
  quickContact: QuickContactResponse;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteQuickContactModal = ({ quickContact, isOpen, onClose }: DeleteQuickContactModalProps) => {
  const confirmCancelRef = useRef(null);
  const translations = useTranslations();
  const { quickContactsState } = useQuickContactsFilterContext();
  const { mutate: deleteQuickContact } = useDeleteQuickContact();

  const handleDelete = () => {
    if (quickContactsState.selectedFnbGroupId) {
      const request = { id: quickContact.id, type: quickContact.type };
      deleteQuickContact({ fnbGroupId: quickContactsState.selectedFnbGroupId, deleteRequest: request });
      onClose();
    }
  };

  return (
    <Dialog
      leastDestructiveRef={confirmCancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleDelete}
      confirmText={translations('delete')}
      cancelText={translations('cancel')}
      dialogHeader={translations('quick_contacts_delete_header')}
    >
      <Box>
        <Text pb={4}>{translations('quick_contacts_delete_confirm_text')}</Text>
      </Box>
    </Dialog>
  );
};
