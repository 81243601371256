import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { QuickContactType } from '../../../../model/enums/quick-contact-type.enum';

export const QuickContactUserTypeRadioSelect = () => {
  const translations = useTranslations();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="type"
      render={({ field: { value, onChange } }) => (
        <RadioGroup onChange={onChange} value={value}>
          <Text mb={2}>{translations('quick_contacts_add_new_contact_type')}</Text>
          <Stack direction="row">
            <Radio size="lg" value={QuickContactType.USER}>
              {translations('quick_contacts_user_type')}
            </Radio>
            <Radio size="lg" value={QuickContactType.RESTAURANT}>
              {translations('quick_contacts_restaurant_type')}
            </Radio>
          </Stack>
        </RadioGroup>
      )}
    />
  );
};
