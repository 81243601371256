import { useContext } from 'react';

import { QuickContactsFilterContextInterface } from '../model/QuickContactsFilterContext';
import { QuickContactsContext } from '../QuickContactsContext';

export const useQuickContactsFilterContext = (): QuickContactsFilterContextInterface => {
  const ctx = useContext(QuickContactsContext);
  if (!ctx) {
    throw new Error('No context provided for QuickContacts');
  }
  return ctx;
};
