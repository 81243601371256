import { useCallback, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { useQuickContacts } from '../../../../../services/quick-contacts';
import { useQuickContactsFilterContext } from '../../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { QuickContactResponse } from '../../../model/quick-contact-response';
import { mapQuickContactResponseToTableRow } from './quick-contacts-table.helper';

export const useQuickContactsTable = () => {
  const [selectedQuickContact, setSelectedQuickContact] = useState<QuickContactResponse | null>(null);
  const deleteQuickContactModal = useDisclosure();
  const { quickContactsState } = useQuickContactsFilterContext();

  const {
    data: quickContacts,
    isLoading: isLoadingQuickContacts,
    isFetching: isFetchingQuickContacts,
  } = useQuickContacts(quickContactsState.selectedFnbGroupId);

  const handleOpenDeleteModal = useCallback(
    (original: QuickContactResponse) => () => {
      setSelectedQuickContact(original);
      deleteQuickContactModal.onOpen();
    },
    [deleteQuickContactModal],
  );

  const handleCloseDeleteModal = () => {
    deleteQuickContactModal.onClose();
    setSelectedQuickContact(null);
  };
  return {
    quickContacts:
      quickContactsState.newOrder ??
      quickContacts?.map((quickContact) => mapQuickContactResponseToTableRow(quickContact)),
    isLoadingQuickContacts: isLoadingQuickContacts || isFetchingQuickContacts,
    selectedQuickContact,
    setSelectedQuickContact,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    isDeleteModalOpen: deleteQuickContactModal.isOpen,
  };
};
