import React from 'react';
import { Button } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { AddNewContactsModal } from './components/AddNewContactsModal/AddNewContactsModal';
import { AddNewContactsResultModal } from './components/AddNewContactsResultModal/AddNewContactsResultModal';
import { useAddNewContacts } from './utils/use-add-new-contacts.hook';

export const AddNewQuickContact = () => {
  const translations = useTranslations();
  const { fnbGroups, quickContactsState } = useQuickContactsFilterContext();
  const {
    addNewContactsModal,
    addNewContactsResultModal,
    isCreatingNewContacts,
    createQuickContactsResult,
    handleSubmit,
  } = useAddNewContacts(quickContactsState.selectedFnbGroupId);

  return (
    <>
      <Button
        isDisabled={!fnbGroups.length || quickContactsState.isReorderMode}
        variant="orangeSolid"
        onClick={addNewContactsModal.onOpen}
        width={'100%'}
      >
        {translations('quick_contacts_add_new_contact_button')}
      </Button>
      <AddNewContactsModal
        isOpen={addNewContactsModal.isOpen}
        onClose={addNewContactsModal.onClose}
        onSubmit={handleSubmit}
        isLoading={isCreatingNewContacts}
      />
      {createQuickContactsResult ? (
        <AddNewContactsResultModal
          isVisible={addNewContactsResultModal.isOpen}
          result={createQuickContactsResult}
          onCancel={addNewContactsResultModal.onClose}
        />
      ) : null}
    </>
  );
};
