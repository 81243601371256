import React, { JSX, useRef } from 'react';
import { AlertDialogFooter, Button } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { QuickContactsCreateResponse } from '../../../../model/quick-contacts-create-response';
import { AddNewContactsResultModalBody } from '../AddNewContactsResultModalBody/AddNewContactsResultModalBody';

type ItemsSummaryModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  result: QuickContactsCreateResponse;
};
export const AddNewContactsResultModal = ({
  isVisible,
  onCancel,
  result,
}: ItemsSummaryModalProps): JSX.Element | null => {
  const translations = useTranslations();
  const cancelRef = useRef(null);

  return (
    <Dialog
      dialogHeader={translations('quick_contacts_create_result_modal_header')}
      size="3xl"
      isOpen={isVisible}
      onClose={onCancel}
      leastDestructiveRef={cancelRef}
      dialogFooter={
        <AlertDialogFooter justifyContent="flex-start">
          <Button variant="orangeSolid" onClick={onCancel}>
            {translations('ok')}
          </Button>
        </AlertDialogFooter>
      }
    >
      <AddNewContactsResultModalBody
        failureItems={result.notCreatedQuickContacts}
        successItems={result.createdQuickContacts}
      />
    </Dialog>
  );
};
