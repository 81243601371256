import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useCreateQuickContacts } from '../../../../../services/quick-contacts';
import { QuickContactsCreateResponse } from '../../../model/quick-contacts-create-response';
import { mapCreateFormToCreateRequest } from '../../../utils/quick-contacts.helper';
import { CreateNewContactForm } from '../components/AddNewContactsModal/form/create-new-contact-form';

export const useAddNewContacts = (fnbGroupId?: string) => {
  const translations = useTranslations();
  const addNewContactsModal = useDisclosure();
  const addNewContactsResultModal = useDisclosure();
  const [createQuickContactsResult, setCreateQuickContactsResult] = useState<QuickContactsCreateResponse | undefined>();
  const { mutate: createNewContacts, isLoading: isCreatingNewContacts } = useCreateQuickContacts();

  const handleSubmit = (form: CreateNewContactForm, onSuccess: () => void) => {
    if (fnbGroupId) {
      const createRequest = mapCreateFormToCreateRequest(form);
      createNewContacts(
        {
          fnbGroupId,
          createRequest: { quickContactsElementsSorted: createRequest },
        },
        {
          onSuccess: (result) => {
            setCreateQuickContactsResult(result);
            onSuccess();
            if (!result.notCreatedQuickContacts.length) {
              toast.success(translations('quick_contacts_create_success_message'));
            } else {
              addNewContactsResultModal.onOpen();
            }
          },
        },
      );
    } else {
      toast.info(translations('quick_contacts_fnb_group_missing_message'));
    }
  };

  return {
    addNewContactsModal,
    addNewContactsResultModal,
    createQuickContactsResult,
    isCreatingNewContacts,
    handleSubmit,
  };
};
