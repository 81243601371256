import React from 'react';
import { Flex } from '@chakra-ui/react';

import { AddNewQuickContact } from '../AddNewQuickContact/AddNewQuickContact';
import { ReorderContacts } from '../ReorderContacts/ReorderContacts';

export const QuickContactsManagement = () => {
  return (
    <Flex direction={'row'}>
      <Flex width={['100%', '100%', '50%', '50%']} m={2}>
        <ReorderContacts />
      </Flex>
      <Flex width={['100%', '100%', '50%', '50%']} m={2}>
        <AddNewQuickContact />
      </Flex>
    </Flex>
  );
};
