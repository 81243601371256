import React from 'react';
import { Box } from '@chakra-ui/react';

import { QuickContactsFnbGroupSelect } from '../QuickContactsFnbGroupSelect/QuickContactsFnbGroupSelect';
import { QuickContactsSearchInput } from '../QuickContactsSearchInput/QuickContactsSearchInput';

export const QuickContactsFilters = () => {
  return (
    <>
      <Box width={['100%', '100%', '50%', '50%']} m={2}>
        <QuickContactsSearchInput />
      </Box>
      <Box width={['100%', '100%', '50%', '50%']} m={2}>
        <QuickContactsFnbGroupSelect />
      </Box>
    </>
  );
};
