import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import RestaurantMultiSelect from '../../../../../../common/components/RestaurantSelect/RestaurantMultiSelect';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../../../../../services/restaurants';
import { useNotificationSettingsContext } from '../../context/hooks/use-notification-settings-context.hook';
import { NotificationSettingsLocations } from '../NotificationSettingsTable/model/enums/notification-settings-locations.enum';

export const NotificationLocations = () => {
  const translations = useTranslations();
  const { isEditMode } = useNotificationSettingsContext();
  const { restaurants } = useRestaurantsData();
  const { control, watch } = useFormContext();

  const watchLocationsSetting = watch('locationsSetting');

  return (
    <Stack direction="row" alignItems="center">
      <Controller
        control={control}
        name="locationsSetting"
        render={({ field: { value, onChange } }) => (
          <RadioGroup onChange={onChange} value={value} isDisabled={!isEditMode}>
            <Stack direction="row">
              <Radio value={NotificationSettingsLocations.ALL}>
                {translations('notification_settings_locations_all')}
              </Radio>
              <Radio value={NotificationSettingsLocations.SELECTED}>
                {translations('notification_settings_locations_selected')}
              </Radio>
            </Stack>
          </RadioGroup>
        )}
      />
      {watchLocationsSetting === NotificationSettingsLocations.SELECTED ? (
        <Controller
          control={control}
          name="selectedLocations"
          render={({ field: { value, onChange } }) => (
            <RestaurantMultiSelect
              menuPortalTarget={document.body}
              isDisabled={!isEditMode}
              selectedRestaurantIds={value}
              userRestaurants={restaurants}
              onSelectRestaurants={(e) => onChange(e.map((v) => v.value))}
            />
          )}
        />
      ) : null}
    </Stack>
  );
};
