import React, { JSX, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { useSessionContext } from 'contexts/SessionContext/hooks';

import { Routes } from '../../../../config/routes';
import { useRestaurantsData } from '../../../../services/restaurants';
import colors from '../../../../styles/colors';
import { LinkButton } from '../../LinkButton';
import { getLinkButtonSvgImgSrc, getSvgImgSrc, isMenuButtonActive } from './utils/menuHelper';
import MENU_ITEMS from './utils/menuItemsConsts';
import { ExpandableSection } from './ExpandableSection';
import { MenuItemImage } from './MenuItemImage';
import { MenuSubsectionArrowImage } from './MenuSubsectionArrowImage';
import { SubSectionButton } from './SubSectionButton';

export const Menu = (): JSX.Element => {
  const translations = useTranslations();
  const { pathname } = useLocation();
  const { role } = useSessionContext();
  const { isDepositEnabled } = useRestaurantsData();
  const [expandableSectionsVisibility, setExpandableSectionsVisibility] = useState<Record<string, boolean>>({
    menuSettings: false,
  });

  const menuItems = MENU_ITEMS.filter((item) => (item.roles ? item.roles.includes(role!) : true)).filter((item) =>
    item.route === Routes.DEPOSIT_PATH ? isDepositEnabled : true,
  );

  useEffect(() => {
    if (!Object.keys(expandableSectionsVisibility).length) {
      const filteredSections = menuItems.reduce<Record<string, boolean>>((acc, curr) => {
        if ('subSections' in curr) {
          acc[curr.translationKey] = false;
        }
        return acc;
      }, {});

      setExpandableSectionsVisibility((sections) => ({
        ...sections,
        ...filteredSections,
      }));
    }
    // eslint-disable-next-line
  }, []);

  const toggleSubsectionVisibility = (translationKey: string) => {
    setExpandableSectionsVisibility((currentVisibility) => ({
      ...currentVisibility,
      [translationKey]: !currentVisibility[translationKey],
    }));
  };

  const handleItemVisibility = (translationKey: string) => () => toggleSubsectionVisibility(translationKey);

  return (
    <>
      {role ? (
        <Flex justifyContent="space-evenly" direction="column" flexGrow={1} data-testid="menu">
          {menuItems.map((item) => (
            <React.Fragment key={item.translationKey}>
              {item?.subSections ? (
                <>
                  <Button
                    variant="menuItemButton"
                    onClick={handleItemVisibility(item.translationKey)}
                    color={isMenuButtonActive(item, pathname) ? colors.black : colors.grey[200]}
                    leftIcon={<MenuItemImage src={getSvgImgSrc(item, pathname)} />}
                  >
                    {translations(item.translationKey)}
                    <MenuSubsectionArrowImage isSectionExpanded={expandableSectionsVisibility[item.translationKey]} />
                  </Button>
                  {expandableSectionsVisibility[item.translationKey] && (
                    <ExpandableSection>
                      {item.subSections
                        .filter((subsection) => subsection.roles?.includes(role))
                        .map((subSection) => (
                          <SubSectionButton
                            type="link"
                            route={subSection.route}
                            color={pathname === subSection.route ? colors.black : colors.grey[200]}
                            bg={pathname === subSection.route ? colors.white : colors.beige[50]}
                            activeTextColor={colors.black}
                            key={subSection.translationKey}
                            leftIcon={<MenuItemImage src={getLinkButtonSvgImgSrc(subSection, pathname)} />}
                          >
                            {translations(subSection.translationKey)}
                          </SubSectionButton>
                        ))}
                    </ExpandableSection>
                  )}
                </>
              ) : (
                <LinkButton
                  variant="menuItemButton"
                  color={pathname === item.route ? colors.black : colors.grey[200]}
                  bg={pathname === item.route ? colors.white : colors.beige[50]}
                  leftIcon={<MenuItemImage src={getLinkButtonSvgImgSrc(item, pathname)} />}
                  to={item.route!}
                >
                  {translations(item.translationKey)}
                </LinkButton>
              )}
            </React.Fragment>
          ))}
        </Flex>
      ) : null}
    </>
  );
};
