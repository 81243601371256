import * as yup from 'yup';

import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { QuickContactType } from '../../../../../model/enums/quick-contact-type.enum';

export const useAddQuickContactsFormValidation = () => {
  const translations = useTranslations();

  return yup.object().shape({
    type: yup
      .string()
      .required()
      .oneOf([...Object.values(QuickContactType)]),
    nickname: yup.string().when(['type'], {
      is: (type: QuickContactType) => type === QuickContactType.USER,
      then: (schema) => schema.required(translations('validation_required')),
    }),
    selectedRestaurants: yup.array().when(['type'], {
      is: (type: QuickContactType) => type === QuickContactType.RESTAURANT,
      then: (schema) =>
        schema.test('test restaurants', translations('validation_required'), (restaurants) => !!restaurants?.length),
    }),
  });
};
