import React, { useMemo } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { DndTable } from '../../../../common/components/Table/DndTable';
import { RowDragHandleCell } from '../../../../common/components/Table/RowDragHandleCell';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { QuickContactType } from '../../model/enums/quick-contact-type.enum';
import { DeleteQuickContactModal } from '../DeleteQuickContact/DeleteQuickContactModal';
import { QuickContactTableRow } from './model/quick-contact-table-row';
import { useQuickContactsTable } from './utils/use-quick-contacts-table.hook';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

export const QuickContactsTable = () => {
  const translations = useTranslations();
  const {
    quickContacts,
    isLoadingQuickContacts,
    selectedQuickContact,
    handleCloseDeleteModal,
    isDeleteModalOpen,
    handleOpenDeleteModal,
  } = useQuickContactsTable();

  const { quickContactsState, handleSetNewOrder } = useQuickContactsFilterContext();

  const columns = useMemo<ColumnDef<QuickContactTableRow>[]>(
    () => [
      {
        id: 'drag-handle',
        cell: ({ row }) => (
          <RowDragHandleCell rowId={row.original.uniqueId} isDisabled={!quickContactsState.isReorderMode} />
        ),
      },
      {
        accessorKey: 'quickContactName',
        header: translations('quick_contacts_client_header'),
        cell: ({ row }) => (
          <Flex alignItems="center">
            <Image
              src={getIconPath(row.original.type === QuickContactType.RESTAURANT ? 'pin.svg' : 'user.svg')}
              my="auto"
              mr={2}
              alt="pin"
            />
            <Text>{row.original.quickContactName}</Text>
          </Flex>
        ),
      },

      {
        id: 'actions',
        cell: ({ row }) => (
          <Flex>
            <IconButton
              isDisabled={quickContactsState.isReorderMode}
              variant={'redSolid'}
              aria-label="Delete"
              icon={<DeleteIcon />}
              onClick={handleOpenDeleteModal(row.original)}
            />
          </Flex>
        ),
      },
    ],
    // eslint-disable-next-line
    [quickContactsState.isReorderMode], // FYI: cannot include translations and handleOpenDeleteModal to not spoil the reorder function, don't know why yet
  );

  return (
    <>
      <FullPageLoader show={isLoadingQuickContacts}>
        {quickContacts ? (
          <DndTable
            isReorderMode={quickContactsState.isReorderMode}
            entry={quickContacts}
            search={quickContactsState.search}
            columns={columns}
            variant={'quickContacts'}
            onNewOrder={handleSetNewOrder}
          />
        ) : null}
      </FullPageLoader>
      {selectedQuickContact ? (
        <DeleteQuickContactModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          quickContact={selectedQuickContact}
        />
      ) : null}
    </>
  );
};
