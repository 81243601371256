import colors from 'styles/colors';

export default {
  variants: {
    pageHeading: {
      fontFamily: 'Rubik',
      fontSize: '28px',
      color: `${colors.black}`,
      textAlign: 'left',
      marginLeft: '20px',
      paddingTop: '30px',
    },
    pageSubheading: {
      fontFamily: 'Rubik',
      fontSize: '14px',
      color: `${colors.black}`,
      textAlign: 'left',
      marginLeft: '20px',
      paddingTop: '30px',
      fontWeight: 400,
    },
    returnStationHeading: {
      fontFamily: 'Rubik',
      fontSize: '46px',
      color: `${colors.black}`,
      textAlign: 'center',
    },
    warningHeading: {
      fontFamily: 'Rubik',
      fontSize: '16px',
      color: 'grey.900',
      textAlign: 'left',
    },
  },
};
