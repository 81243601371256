import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { QUICK_CONTACTS_FNB_GROUPS_KEY, QUICK_CONTACTS_KEY, QUICK_CONTACTS_RESTAURANTS_KEY } from '../api/apiConsts';
import {
  createQuickContacts,
  deleteQuickContact,
  getFnbGroups,
  getQuickContacts,
  getRestaurants,
  reorderQuickContacts,
} from '../api/quick-contacts';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { QuickContactsCreateRequest } from '../modules/QuickContacts/model/quick-contact-create-request';
import { QuickContactFnbGroupResponse } from '../modules/QuickContacts/model/quick-contact-fnb-group-response';
import { QuickContactRequest } from '../modules/QuickContacts/model/quick-contact-request';
import { QuickContactResponse } from '../modules/QuickContacts/model/quick-contact-response';
import { QuickContactRestaurantResponse } from '../modules/QuickContacts/model/quick-contact-restaurant-response';
import { QuickContactsCreateResponse } from '../modules/QuickContacts/model/quick-contacts-create-response';
import { QuickContactsReorderRequest } from '../modules/QuickContacts/model/quick-contacts-reorder-request';

const getQuickContactsQueryKey = (fnbGroupId: string) => {
  return [QUICK_CONTACTS_KEY, fnbGroupId];
};

const getQuickContactsRestaurantsQueryKey = (fnbGroupId: string) => {
  return [QUICK_CONTACTS_RESTAURANTS_KEY, fnbGroupId];
};

export const useQuickContacts = (fnbGroupId?: string): QueryObserverResult<QuickContactResponse[], AxiosError> => {
  return useQuery(getQuickContactsQueryKey(fnbGroupId || ''), () => getQuickContacts(fnbGroupId || ''), {
    refetchOnWindowFocus: false,
    enabled: !!fnbGroupId,
  });
};

export const useQuickContactsFnbGroups = (): QueryObserverResult<QuickContactFnbGroupResponse[], AxiosError> => {
  return useQuery(QUICK_CONTACTS_FNB_GROUPS_KEY, () => getFnbGroups(), {
    refetchOnWindowFocus: false,
  });
};

export const useQuickContactsRestaurants = (
  fnbGroupId?: string,
): QueryObserverResult<QuickContactRestaurantResponse[], AxiosError> => {
  return useQuery(getQuickContactsRestaurantsQueryKey(fnbGroupId || ''), () => getRestaurants(fnbGroupId || ''), {
    refetchOnWindowFocus: false,
    enabled: !!fnbGroupId,
  });
};

export const useReorderQuickContacts = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { fnbGroupId: string; reorderRequest: QuickContactsReorderRequest }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ fnbGroupId, reorderRequest }: { fnbGroupId: string; reorderRequest: QuickContactsReorderRequest }) =>
      reorderQuickContacts(fnbGroupId, reorderRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUICK_CONTACTS_KEY);
        toast.success(translations('quick_contacts_reorder_success_message'));
        onSuccess();
      },
      onError: (error) => {
        toast.error(error.response?.data.message || error.response?.data.error || translations('something_went_wrong')); // todo: remove error.response?.data.error once translations are added on the backend side
      },
    },
  );
};

export const useCreateQuickContacts = (): UseMutationResult<
  QuickContactsCreateResponse,
  AxiosError<HttpException>,
  {
    fnbGroupId: string;
    createRequest: QuickContactsCreateRequest;
  }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ fnbGroupId, createRequest }: { fnbGroupId: string; createRequest: QuickContactsCreateRequest }) =>
      createQuickContacts(fnbGroupId, createRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUICK_CONTACTS_KEY);
      },
      onError: (error) => {
        toast.error(error.response?.data.message || error.response?.data.error || translations('something_went_wrong')); // todo: remove error.response?.data.error
      },
    },
  );
};

export const useDeleteQuickContact = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  {
    fnbGroupId: string;
    deleteRequest: QuickContactRequest;
  }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ fnbGroupId, deleteRequest }: { fnbGroupId: string; deleteRequest: QuickContactRequest }) =>
      deleteQuickContact(fnbGroupId, deleteRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUICK_CONTACTS_KEY);
        toast.success(translations('quick_contacts_delete_success_message'));
      },
      onError: (error) => {
        toast.error(error.response?.data.message || error.response?.data.error || translations('something_went_wrong')); // todo: remove error.response?.data.error
      },
    },
  );
};
