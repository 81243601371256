import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['label', 'container', 'control']);

const Radio = helpers.defineMultiStyleConfig({
  baseStyle: {
    label: {
      _disabled: {
        opacity: 0.7,
      },
    },
    control: {
      borderColor: 'grey.100',
      border: '1px',
      _checked: {
        borderColor: 'orange.500',
        background: 'white',
        color: 'orange.500',
        _hover: {
          background: 'white',
          borderColor: 'orange.500',
        },
      },
      _disabled: {
        _checked: {
          borderColor: 'orange.400',
          background: 'white',
          color: 'orange.400',
        },
        borderColor: 'grey.70',
        background: 'white',
      },
      _focus: {
        background: 'white',
        borderColor: 'orange.500',
      },
      _hover: {
        background: 'white',
      },
    },
  },
  sizes: {
    md: {
      label: { fontSize: '14px' },
    },
    lg: {
      label: { fontSize: '16px' },
    },
  },
});

export default Radio;
