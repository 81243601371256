import React, { ChangeEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';

export const QuickContactsSearchInput = () => {
  const translations = useTranslations();

  const { quickContactsState, fnbGroups, handleSetSearchText } = useQuickContactsFilterContext();

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetSearchText(event.target.value);
  };
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="grey.200" />
      </InputLeftElement>
      <Input
        value={quickContactsState.search}
        variant="whiteFilled"
        placeholder={translations('quick_contacts_searchbox_placeholder')}
        onChange={handleInputOnChange}
        isDisabled={!fnbGroups.length || quickContactsState.isReorderMode}
      />
    </InputGroup>
  );
};
