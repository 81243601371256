import React from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Button } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';
import { QuickContactType } from '../../../../model/enums/quick-contact-type.enum';
import { CreateNewContactForm } from '../AddNewContactsModal/form/create-new-contact-form';
import { QuickContactRestaurants } from '../QuickContactRestaurants/QuickContactRestaurants';
import { QuickContactUserNickname } from '../QuickContactUserNickname/QuickContactUserNickname';
import { QuickContactUserTypeRadioSelect } from '../QuickContactUserTypeRadioSelect/QuickContactUserTypeRadioSelect';

type AddNewContactsModalBodyProps = {
  onSubmit: (form: CreateNewContactForm) => void;
  isLoading: boolean;
};

export const AddNewContactsModalBody = ({ onSubmit, isLoading }: AddNewContactsModalBodyProps) => {
  const translations = useTranslations();
  const { watch, handleSubmit } = useFormContext<CreateNewContactForm>();
  const { quickContactsState } = useQuickContactsFilterContext();
  const watchType = watch('type');

  const handleFormSubmit = handleSubmit((form) => {
    if (quickContactsState.selectedFnbGroupId) {
      onSubmit(form);
    } else {
      toast.info(translations('quick_contacts_fnb_group_missing_message'));
    }
  });

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Box mb={4}>
          <QuickContactUserTypeRadioSelect />
        </Box>
        {watchType === QuickContactType.USER ? (
          <Box mb={4}>
            <QuickContactUserNickname />
          </Box>
        ) : null}
        {watchType === QuickContactType.RESTAURANT ? (
          <Box mb={4}>
            <QuickContactRestaurants />
          </Box>
        ) : null}
        <Button variant="orangeSolid" type="submit" width="100%" isLoading={isLoading}>
          {translations('quick_contacts_add_new_contact_save_button')}
        </Button>
      </form>
    </>
  );
};
